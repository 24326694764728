<template>
  <div v-loading="loading">
    <eden-page-header
      :title="'Rider Directory'"
      :subtitle="formatFullName(rider)"
    >
      <template slot="actions">
        <el-dropdown @command="command" class="more">
          <el-button type="plain">
            Actions <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="status"
              >Change Rider Status</el-dropdown-item
            >

            <el-dropdown-item class="text-danger" command="delete"
              >Delete rider</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </eden-page-header>
    <eden-information-card>
      <template slot="content">
        <div class="rider-profile">
          <div class="rider-profile--summary">
            <eden-image :image-size="60" :image-url="rider.avatar" />
            <p class="name mb-1">{{ formatFullName(rider) }}</p>
            <el-tag :class="'small'" :type="setType(rider.status)">{{
              formatText(rider.status)
            }}</el-tag>
            <el-button :type="'plain'" :size="'small'" @click="edit"
              >Edit Profile</el-button
            >
          </div>
          <eden-information-card
            class="no-border min-width"
            :title="'Rider Information'"
          >
            <template slot="content">
              <el-table
                :class="'general'"
                :data="riderInformation"
                :show-header="false"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <span class="title font-xsm">{{ scope.row.label }}</span>
                    <p class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </eden-information-card>
          <eden-information-card
            class="no-border min-width"
            :title="'Bank Information'"
          >
            <template slot="content">
              <el-table
                :class="'general'"
                :data="bankInformation"
                :show-header="false"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <span class="title font-xsm">{{ scope.row.label }}</span>
                    <p class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </eden-information-card>
        </div>
      </template>
    </eden-information-card>
    <RiderPayouts :rider-name="formatFullName(rider)" />
    <RiderDelete
      :show.sync="riderAction.visibility"
      :rider-id="riderId"
      @success="handleDeleteSuccess"
    />
    <RiderStatus :show.sync="showStatus" :rider="rider" @success="getRider" />
  </div>
</template>

<script>
import riders from "@/requests/marketplace-logistics/riders";
import RiderDelete from "@/components/MarketplaceLogistics/Directory/Riders/Actions/RiderDelete.vue";
import RiderStatus from "@/components/MarketplaceLogistics/Directory/Riders/Actions/RiderStatus.vue";
import RiderPayouts from "../../../../components/MarketplaceLogistics/Directory/Riders/RiderPayouts.vue";

export default {
  name: "DirectoryRider",
  components: {
    RiderDelete,
    RiderStatus,
    RiderPayouts,
  },
  data() {
    return {
      loading: false,
      rider: {},
      riderAction: {
        action: "add",
        visibility: false,
        data: {},
      },
      showStatus: false,
      singleRider: {},
    };
  },
  computed: {
    riderId() {
      return this.$route.params.id;
    },
    riderInformation() {
      return [
        {
          label: "Phone Number",
          text: this.formatPhoneNumber(this.rider.phone_number),
        },
        {
          label: "Email address",
          text: this.rider.email,
        },
        {
          label: "Company",
          text: this.rider.company && this.rider.company.name,
        },
      ];
    },
    bankInformation() {
      return [
        {
          label: "Bank Name",
          text: this.rider.bank_name,
        },
        {
          label: "Account Number",
          text: this.rider.account_number,
        },
        {
          label: "Account Name",
          text: this.rider.account_name,
        },
      ];
    },
  },
  created() {
    this.getRider();
  },
  methods: {
    command(command) {
      switch (command) {
        case "status":
          this.riderAction.action = "status";
          this.showStatus = true;
          break;
        case "delete":
          this.riderAction.action = "delete";
          this.riderAction.visibility = true;
          break;
      }
    },
    getRider() {
      this.loading = true;
      riders
        .get(this.riderId)
        .then((response) => {
          if (response.data.status) {
            this.rider = response.data.data;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    edit() {
      this.$router.push({
        name: "marketplace-logistics.directory.rider-edit",
        params: { id: this.riderId },
      });
    },
    handleDeleteSuccess() {
      this.$router.push({
        name: "marketplace-logistics.directory.index",
        query: { t: "riders" },
      });
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
$profile-width: 320px;
.information-card {
  padding: 0;
  margin-bottom: 48px;

  &.no-border {
    border: 0;
    margin-bottom: 0;
    padding: 16px 0;
    width: 35%;

    div.title {
      padding: 16px !important;
    }

    @media (min-width: 768px) {
      &:not(:last-child) {
        border-right: 1px solid #f0f4f2;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    @media (max-width: 1200px) {
      border-top: 1px solid #f0f4f2;
      border-radius: 0;
    }
  }
}
.rider-profile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &--summary {
    padding: 16px 0;
    text-align: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #f0f4f2;

    .name {
      font-weight: 500;
      font-size: 1.125rem;
      margin: 5px 0;
    }

    .phone {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--eden-grey-tertiary);
      display: block;
      margin: 5px 0;
    }

    .el-button {
      margin: 15px auto;
    }
  }
  @media (min-width: 1300px) {
    .rider-profile--summary {
      border-right: 1px solid #f0f4f2;
    }
  }

  .general {
    tr:last-child {
      td {
        padding: 0;

        .cell {
          padding: 0;
        }
      }
    }
  }
}
</style>
