<template>
  <section>
    <eden-loader v-if="loading" />

    <template v-else>
      <el-table :data="payouts" v-if="payouts?.length" :key="sort.property">
        <el-table-column>
          <template slot="header">
            <eden-table-column-header
              :label="'Date'"
              :property="'date'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'date')"
            />
          </template>
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.date_payed_for) }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <eden-table-column-header
              :label="'Orders Delivered'"
              :property="'orders'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'orders')"
            />
          </template>
          <template slot-scope="scope">
            <span class="font-xsm text-grey-tertiary">
              {{ scope.row.orders_delivered_count }}</span
            >
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <eden-table-column-header
              :label="'Payout Amount'"
              :property="'payout'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'payout')"
            />
          </template>
          <template slot-scope="scope">
            <p>
              {{ formatPrice(scope.row.total_delivery_fee_paid) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <eden-table-column-header
              :label="'Payout Status'"
              :property="'status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'status')"
            />
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              <el-tag :type="setType(scope.row.status)"
                >{{ formatText(scope.row.status) }}
              </el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header"> </template>
          <template slot-scope="scope">
            <span class="font-base text-bold">
              <p>
                <router-link
                  class="text-primary"
                  style="text-decoration: underline"
                  :to="{
                    name: 'marketplace-logistics.directory.rider-orders',
                    params: { id: scope.row.uuid, name: riderName },
                  }"
                >
                  View Details
                </router-link>
              </p>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No Payouts'" />
      <eden-pagination
        v-if="payouts.length"
        :from="pagination.from"
        :to="pagination.to"
        :total="pagination.total"
        :current-page.sync="page"
      />
    </template>
  </section>
</template>

<script>
import riders from "@/requests/marketplace-logistics/riders";
export default {
  name: "RiderPayouts",
  props: {
    riderName: String,
  },
  data() {
    return {
      payouts: [],
      pagination: {},
      page: 1,
      loading: false,
      sort: {
        property: "date",
        direction: "asc",
      },
    };
  },
  computed: {
    riderId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getPayouts();
  },
  methods: {
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.payouts = this.sortList(this.payouts, property, direction);
    },
    getPayouts() {
      this.loading = true;
      riders
        .payout(this.riderId)
        .then((response) => {
          if (response.data.status) {
            this.payouts = response.data.data;
            this.pagination = response.data.meta;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
